<template>
  <div class="backgroundCol">
    <b-container>
      <b-row>
        <b-col lg="9" md="12" cols="12" class="newsFont">
          <b-row v-if="this.$route.fullPath.includes('id=')">
            <b-col>
               <b-link :to="'/tournament/' + this.$route.query.id.replace('?season=', '/')" class="linkColor">
                <button class="backTourn">Back To Tournament</button>
               </b-link>
            </b-col>
          </b-row>
          <b-row class="pad wholePage">
            <b-col>
              <h2
                class="newsTitle"
                v-html="postInfo[0].title.rendered"
              ></h2>
            </b-col>
          </b-row>
          <b-row class="pad">
            <b-col>
              <p class="publishedData">Published {{ dateTime }}</p>
            </b-col>
          </b-row>
          <b-row class="pad">
            <b-col class="social">
              <ShareNetwork
                  network="facebook"
                  :url="'https://asiantour.com/' + this.postInfo[0].slug"
              >
                <font-awesome-icon
                  class="icon"
                  :icon="['fab', 'facebook-square']"
                />
              </ShareNetwork>
              <!-- <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.network"
                :style="{backgroundColor: network.color}"
                :url="url"
                :title="title"
              >
                <i :class="network.icon"></i>
                <span>{{ network.name }}</span>
              </ShareNetwork> -->
              <!-- Your share button code -->
              <!-- <div class="fb-share-button" 
              :data-href="yoastinfo.replace(/wp-asiantour.ocs-sport.com/g, 'asiantour.com')" 
              data-layout="button_count">
              </div>
              <div id="fb-root"></div>
              <script type="application/javascript">(function(d, s, id) {
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) return;
              js = d.createElement(s); js.id = id;
              js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
              fjs.parentNode.insertBefore(js, fjs);
              }(document, 'script', 'facebook-jssdk'));</script> -->
            </b-col>
            <b-col class="social">
              <ShareNetwork
                network="twitter"
                :url="'https://asiantour.com/' + id"
                :title="postInfo[0].title.rendered"
                hashtags="asiantour"
              >
                <font-awesome-icon class="icon" :icon="['fab', 'twitter']" />
              </ShareNetwork>
            </b-col>
            <b-col class="social">
              <ShareNetwork
                network="linkedin"
                :url="'https://asiantour.com/' + id"
                :title="postInfo[0].title.rendered"
                hashtags="asiantour"
              >
                <font-awesome-icon class="icon" :icon="['fab', 'linkedin']" />
              </ShareNetwork>
            </b-col>
            <b-col class="col-md-8"> </b-col>
          </b-row>
          <b-row class="pad">
            <b-col>
              <b-img
                class="featureImg"
                :src="postInfo[0].better_featured_image.source_url"
              ></b-img>
            </b-col>
          </b-row>
          <b-row class="pad">
            <b-col>
              <span v-html="postInfo[0].content.rendered"></span>
              <b-row class="BKandPrint">
                <b-col>
                  <b-nav-item class="back" :to="'/news'"> 
                    Back to News
                  </b-nav-item>
                </b-col>
                <b-col>
                  <b-nav-item class="print" onclick="window.print()"> 
                    Print
                  </b-nav-item>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="3" md="12" cols="12" class="col-md-3" :class="{tournNews: this.$route.fullPath.includes('id=')}">
          <b-row>
            <b-col>
              <Newssidebar :develop="develop" :config="config" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Newssidebar from "@/components/newsSidebar.vue";
import axios from "axios";
export default {
  name: "postFull",
  props: ["develop", "config", "id"],
  components: {
    Newssidebar
  },
  data() {
    return {
      title: '',
      url: '',
      // sharing: {
      //   url: '',
      //   title: '',
      //   description: '',
      //   quote: 'The hot reload is so fast it\'s near instant. - Evan You',
      //   twitterUser: 'youyuxi'
      // },
      postInfo: [],
      yoast: [],
      yoast_head: "",
      // title: "",
      // description: "",
      yoastinfo:''
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        { name: "description", content: this.description }
      ]
    };
  },
  computed: {
    dateTime: function() {
      var dateOG = this.postInfo[0].date.split("T");
      var splitDate = dateOG[0].split("-");
      var newDate = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];
      var time = dateOG[1];
      return newDate + " - " + time;
    }
  },
  created() {
    axios
      .get(
        process.env.VUE_APP_WP_ASIAN_TOUR_API_URL +
          "wp/v2/posts?slug=" +
          this.id
      )
      .then(
        response => (
          (this.postInfo = response.data),
          (this.yoastinfo = response.data[0].yoast_head_json.og_url),
          (this.yoast = response.data[0].yoast_meta),
          (this.yoast_head = response.data[0].yoast_title),
          (this.title = response.data[0].title.rendered),
          (this.description = response.data[0].yoast_head_json.og_description)
        )
      );
  }
};
</script>

<style scoped>
.col-md-3.col-md-12.col-lg-3.col-12.tournNews {
  margin-top: 115px;
}
a.linkColor {
  text-decoration: none;
}
.backTourn {
  border-radius: 0px;
  width: 324px;
  height: 59px;
  font-weight: 500;
  color: #fff !important;
  line-height: 3.3;
  text-transform: uppercase;
  border: 0px;
  background-color: #042F62;
  display: block;
  /* margin-top: 30px; */
  font-size: 14px;
  margin-top: 55px;
}
.backTourn:hover {
  border-radius: 0px;
  width: 324px;
  height: 59px;
  font-weight: 500;
  color: #fff !important;
  line-height: 3.3;
  text-transform: uppercase;
  border: 0px;
  background-color: #41a2f6;
  display: block;
  /* margin-top: 30px; */
  font-size: 14px;
  margin-top: 55px;
}
.BKandPrint {
  margin-bottom: 60px;
  margin-top: 60px;
}
::v-deep .icon {
  font-size: 22px;
  color: #A8B7C9;
}
::v-deep .wp-caption.alignright {
  display: inline;
  float: right;
}
::v-deep .wp-caption.alignnone {
  width: 100% !important;
}
::v-deep img.alignnone.wp-image-16577.size-large {
  width: 100%;
  height: auto;
}
::v-deep .wp-caption-text {
  line-height: 1.5;
  font-size: 12px;
  margin: 0;
  text-align: center;
  margin-bottom: 2em;
  color: #042F62;
  margin-top: 0.3em;
}
.social {
  width: 20px;
  max-width: 35px;
}
::v-deep .size-large {
  width: 100%;
  height: auto;
}
p.publishedData {
  font-weight: bold;
  margin-top: 0;
  color: #8D8D8D;
  font-size: 16px;
  margin-bottom: 10px;
}
.newsFont {
  font-size: 14px;
  color: #707070;
}
h2.newsTitle {
  color: #0A3F7F;
  font-size: 26px;
}
.backgroundCol {
  /* background-color: #f1f1f1; */
}
.featureImg {
  width: 100%;
}
.pad {
  padding-top: 2%;
}
.wholePage {
  /* padding-bottom: 6%; */
  margin-top: 3em;
}
::v-deep .logo {
  margin-top: 3.6em;
}
.developGreenText {
  color: #76c3bd !important;
}
li {
  list-style: none;
}
.back:hover {
  background-color: #41a2f6;
}
.back {
  font-size: 14px;
  /* padding-bottom: 20px; */
  color: #FFFFFF!important;
  background-color: #0A3F7F;
  text-align: center;
  padding: 10px;
  width: 324px;
  height: 59px;
}
.back > a {
  color: #FFFFFF!important;
  text-transform: uppercase;
  font-weight: 500;
}
.print {
  font-size: 14px;
  /* padding-bottom: 20px; */
  color: #FFFFFF!important;
  background-color: #A8B7C9;
  text-align: center;
  padding: 10px;
  width: 324px;
  height: 59px;
}
.print > a {
  color: #FFFFFF!important;
  text-transform: uppercase;
  font-weight: 500;
}
.print:hover {
  background-color: #41a2f6;
}

::v-deep .wp-caption img {
  border: 0 none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
}
::v-deep .wp-caption {
  margin-bottom: 1.5em;
  text-align: center;
  padding-top: 5px;
  padding-left: 0;
  margin-left: 0;
}
::v-deep .wp-caption {
  margin-bottom: 1.5em;
  text-align: center;
  padding-top: 5px;
}

@media screen and (max-width: 990px) {
  ::v-deep .wp-caption {
    margin-bottom: 1.5em;
    text-align: center;
    padding-top: 5px;
    width: 100% !important;
  }
}
</style>
